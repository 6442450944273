import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Loader, Icon } from '@ui'

import connect from './connect'
import './index.scss'
import { isDefined } from '@app/util'

const SIZES = {
  XS: 'size-xs',
  S: 'size-s',
  M: 'size-m',
  L: 'size-l',
  XL: 'size-xl',
  FULLSCREEN: 'size-full'
}

const SIZE_HEIGHT_MAP = {
  [SIZES.XS]: '12.375rem',
  [SIZES.S]: '20.75rem',
  [SIZES.M]: '41.6875rem',
  [SIZES.L]: '41.6875rem',
  [SIZES.XL]: '41.6875rem',
  [SIZES.FULLSCREEN]: '100vh'
}

const SIZE_WIDTH_MAP = {
  [SIZES.XS]: '17.375rem',
  [SIZES.S]: '17.375rem',
  [SIZES.M]: '17.375rem',
  [SIZES.L]: '33.125rem',
  [SIZES.XL]: '44.875rem',
  [SIZES.FULLSCREEN]: '100vw'
}

const isRem = (v) => v.includes('rem')
const isVh = (v) => v.includes('vh')
const isVw = (v) => v.includes('vw')

const getRawValue = (val) => {
  const _parsedVal = /((?:\d|\.|,)+)\w+/g.exec(val)?.[1]

  return isDefined(_parsedVal) ? parseFloat(_parsedVal) : 0
}

const remToPx = (rem) => {
  const REM_INDEX = getRawValue(window.getComputedStyle(document.documentElement).fontSize)

  return rem * parseFloat(REM_INDEX)
}

const vhToPx = (vh) => {
  const VH_INDEX = window.innerHeight / 100

  return vh * VH_INDEX
}

const vwToPx = (vw) => {
  const VW_INDEX = window.innerWidth / 100

  return vw * VW_INDEX
}

const getModalDimensions = (size) => {
  const heightString = SIZE_HEIGHT_MAP[size]; const widthString = SIZE_WIDTH_MAP[size]
  const isHeightRem = isRem(heightString); const isHeightVh = isVh(heightString)
  const isWidthRem = isRem(widthString); const isWidthVw = isVw(widthString)
  const rawHeight = getRawValue(heightString); const rawWidth = getRawValue(widthString)
  const processedHeight = isHeightRem
    ? remToPx(rawHeight)
    : isHeightVh
      ? vhToPx(rawHeight)
      : rawHeight

  const processedWidth = isWidthRem
    ? remToPx(rawWidth)
    : isWidthVw
      ? vwToPx(rawWidth)
      : rawWidth

  return { height: processedHeight, width: processedWidth }
}

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

// dragging functionality (separated as a component, so there aren't any problems with React Hooks when the whole modal is set to null)
const DraggableContent = ({ size, children }) => {
  const { width: modalWidth, height: modalHeight } = getModalDimensions(size)
  const { height: windowHeight } = getWindowDimensions()

  const calculatedModalHeight = size !== SIZES.FULLSCREEN
    ? modalHeight >= windowHeight
      ? windowHeight
      : modalHeight
    : modalHeight

  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [pos, setPos] = useState({ x: '50%', y: size === SIZES.FULLSCREEN ? '0' : ((modalHeight + 60) < windowHeight) ? '60px' : '0' })

  const isDraggableTarget = (e) => {
    return Boolean(size !== SIZES.FULLSCREEN && document.elementsFromPoint(e.pageX, e.pageY).some((element) => element.classList.contains('ds-modal-header')))
  }

  const handleDragStart = (e) => {
    if (!isDraggableTarget(e) || size === SIZES.FULLSCREEN) {
      e.preventDefault()
    } else {
      setDragStart({ x: e.clientX - e.target.offsetLeft, y: e.clientY - e.target.offsetTop })
    }
  }

  const handleDragEnd = (e) => {
    if (size !== SIZES.FULLSCREEN) {
      setPos({ x: e.clientX - dragStart.x, y: e.clientY - dragStart.y })
      setDragStart({ x: 0, y: 0 })
    }
  }
  return (
    <div
      className={classNames('ds-modal-content')}
      draggable={size !== SIZES.FULLSCREEN}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      style={{
        top: pos.y,
        left: pos.x,
        transform: 'translateX(-50%)',
        width: modalWidth,
        height: calculatedModalHeight,
        margin: size === SIZES.FULLSCREEN ? '0' : undefined
      }}
    >
      {children}
    </div>
  )
}

export const Modal = connect(
  ({
    size,
    headerContent,
    footerContent,
    sections,
    extraHeaderButtons,
    className,
    isLoading,
    children,
    modal,
    noClose,
    close,
    afterClose,
    displayCloseButton,
    testid
  }) => {
    const handleKeypress = (evt) => {
      // Esc
      if (evt.keyCode === 27 && !noClose) {
        close()
        afterClose()
      }
      // Enter
      if (evt.keyCode === 13) {
        const btns = document.querySelectorAll(
          '.ds-modal-footer .ds-button.contained:not(.disabled)'
        )
        if (btns.length) btns[btns.length - 1].click()
      }
    }

    useEffect(() => {
      document.addEventListener('keydown', handleKeypress, false)
      return () => {
        document.removeEventListener('keydown', handleKeypress, false)
      }
    }, [])

    if (!modal) {
      return (
        <div className='ds-modal-container'>
          <div className='ds-modal-underlay' />
        </div>
      )
    }

    const closeIcon = noClose ? null : (
      <Icon
        ico={Icon.ICONS.close1}
        size={Icon.SIZES.SMALL}
        color={Icon.COLORS.PRIMARY}
        onClick={() => {
          close()
          afterClose()
        }}
      />
    )

    const hasHeaderContent = Boolean(headerContent || extraHeaderButtons.length)

    return (
      <div
        data-testid={testid}
        className={classNames('ds-modal-container', className, {
          'is-open': !!modal,
          'show-nav': modal && modal.data && modal.data.showNav,
          'over-sidebar': modal && modal.data && modal.data.overSidebar
        })}
      >
        <div onClick={noClose ? null : () => { close(); afterClose() }} className='ds-modal-underlay' />
        <DraggableContent
          size={size}
        >
          {/* HEADER */}
          {hasHeaderContent && (
            <div className='ds-modal-header'>
              {headerContent}
              <div className='ds-modal-header-buttons'>
                {extraHeaderButtons.length ? extraHeaderButtons : null}
                {displayCloseButton ? (
                  <div className='close-icon-container'>{closeIcon}</div>
                ) : null}
              </div>
            </div>
          )}
          {!hasHeaderContent && displayCloseButton && (
            <div className='just-close'>{closeIcon}</div>
          )}

          {/* SECTIONS */}
          <div className='ds-modal-sections'>
            {isLoading ? (
              <Loader size={Loader.SIZES.SMALL} />
            ) : sections && sections.length ? (
              sections.map((sec, idx) => {
                return (
                  <div className='ds-modal-content-section' key={idx}>
                    {sec}
                  </div>
                )
              })
            ) : (
              children
            )}
          </div>

          {/* FOOTER */}
          {!isLoading && footerContent ? (
            <div className='ds-modal-footer'>{footerContent}</div>
          ) : null}
        </DraggableContent>

      </div>
    )
  }
)

Modal.propTypes = {
  size: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  noClose: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  headerContent: PropTypes.object,
  footerContent: PropTypes.object,
  sections: PropTypes.arrayOf(PropTypes.object),
  extraHeaderButtons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node
  ]),
  close: PropTypes.func,
  afterClose: PropTypes.func,
  children: PropTypes.node,
  modal: PropTypes.object,
  displayCloseButton: PropTypes.bool
}

Modal.defaultProps = {
  size: SIZES.MEDIUM,
  isLoading: false,
  noClose: false,
  style: null,
  className: null,
  extraHeaderButtons: [],
  children: null,
  close: () => null,
  afterClose: () => null,
  displayCloseButton: true
}

Modal.SIZES = SIZES
