"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACC_DETAIL_DATA = exports.PERMISSIONS_PRO = exports.PERMISSIONS = void 0;
const _ui_1 = require("@ui");
exports.PERMISSIONS = {
    EMP_DASHBOARD: 'employeedashboard',
    EMP_CALENDAR: 'employeecalendar',
    EMP_ATTENDANCE: 'employeeattendance',
    MNG_CALENDAR: 'managercalendar',
    CALENDAR: 'managercalendar',
    WORKSPACE: {
        EMPLOYEES: 'employees',
        POSITIONS: 'positions',
        LOCALITIES: 'localities',
        TIMEOFFCATEGORIES: 'timeoffcategories',
        SHIFTTEMPLATES: 'shifttemplates',
        DAYTEMPLATES: 'daytemplates',
        CYCLES: 'cycles',
        SHIFTATTRIBUTES: 'shiftattributes',
        GROUPS: 'groups',
    },
    BACKOFFICE: {
        ATTENDANCE: 'attendance',
        BONUSES: 'bonuses',
        TIMEOFFREQUESTS: 'timeoffrequests',
        REPORT_EMPLOYEE: 'reportemployee',
        REPORT_WORKSPACE: 'reportworkspace',
        REPORT_WARNINGS: 'reportwarnings',
        REPORT_OVERTIME: 'reportovertime',
        REPORT_CONTACTS: 'reportcontacts',
        REPORT_REVENUE: 'reportrevenue',
        TERMINALS: 'terminals',
    },
    SETTINGS: {
        SETTINGSBASIC: 'settingsbasic',
        SETTINGSPERMISSIONS: 'settingsaccessroles',
        SETTINGSNOTIFICATIONS: 'settingsnotifications',
        SETTINGSEMPLOYEES: 'settingsemployees',
        SETTINGSOFFERS: 'settingsoffers',
        SETTINGSCAPACITIES: 'settingscapacities',
        SETTINGSATTENDANCETERMINALS: 'settingsattendanceterminals',
        APIKEYS: 'apikeys',
        PLUGINS: 'plugins',
        AUDITLOGS: 'auditlog',
    },
    COMMUNICATION: {
        NEWS_FEED: 'communication',
        CHAT: 'chat',
    },
};
exports.PERMISSIONS_PRO = {
    DASHBOARD: 'dashboard',
    WORKSPACES: {
        PERFORMANCE: 'workspaces_performance',
        COUNTRIES: 'workspaces_countries',
        AREAS: 'workspaces_areas',
        WORKSPACES: 'workspaces_workspaces',
        POSITIONS: 'workspaces_positions',
        REVENUE: 'workspaces_revenue',
        NORMS: 'workspaces_norms',
        PRODUCTION: 'workspaces_production',
        PLANS: 'workspaces_full_time_plans',
        HOURS: 'workspaces_hours_distribution',
        HOLIDAYS: 'workspaces_holidays',
    },
    TRANSFERS: {
        OVERVIEW: 'transfers_overview',
        DETAILS: 'transfers_details',
        GROUPS: 'transfers_groups',
    },
    PAYROLL: {
        BONUSES_OVERVIEW: 'bonuses_overview',
        BONUSES_MEAL_VOUCHERS: 'bonuses_meal_vouchers',
        BONUSES_SALES: 'bonuses_sales',
        BONUSES_INVENTORY: 'bonuses_inventory',
        ATTENDANCE: 'payroll_attendance',
        ATTENDANCE_LVL2: 'payroll_attendance_lvl2',
        ATTENDANCE_LVL3: 'payroll_attendance_lvl3',
    },
    REPORTS: 'reports',
    PEOPLE: 'people',
    SETTINGS: 'settings',
    BILLING: 'billing',
};
exports.ACC_DETAIL_DATA = [
    {
        item: 'MY_EMP_CALENDAR',
        access: exports.PERMISSIONS.EMP_CALENDAR,
        icon: _ui_1.Icon.ICONS.clock,
    },
    {
        item: 'MY_OVERVIEW',
        disabled: true,
        access: exports.PERMISSIONS.EMP_DASHBOARD,
        icon: _ui_1.Icon.ICONS.clipboard,
    },
    {
        item: 'MY_EMP_ATTENDANCE',
        access: exports.PERMISSIONS.EMP_ATTENDANCE,
        icon: _ui_1.Icon.ICONS.attendance,
    },
    {
        item: 'CALENDAR',
        access: exports.PERMISSIONS.CALENDAR,
        icon: _ui_1.Icon.ICONS.calendar,
    },
    {
        item: 'WORKSPACE',
        access: 'WORKSPACE',
        icon: _ui_1.Icon.ICONS.home,
        nestedItems: [
            {
                item: 'PEOPLES',
                access: exports.PERMISSIONS.WORKSPACE.EMPLOYEES,
            },
            {
                item: 'POSITIONS',
                access: exports.PERMISSIONS.WORKSPACE.POSITIONS,
            },
            {
                item: 'LOCALITIES',
                access: exports.PERMISSIONS.WORKSPACE.LOCALITIES,
            },
            {
                item: 'WS_SETTINGS_CARD_UNAV_CATEGORIES_NAV',
                access: exports.PERMISSIONS.WORKSPACE.TIMEOFFCATEGORIES,
            },
            {
                item: 'SHIFT_TEMPLATES',
                access: exports.PERMISSIONS.WORKSPACE.SHIFTTEMPLATES,
            },
            {
                item: 'DAY_TEMPLATES',
                access: exports.PERMISSIONS.WORKSPACE.DAYTEMPLATES,
            },
            {
                item: 'CYCLES',
                access: exports.PERMISSIONS.WORKSPACE.CYCLES,
            },
            {
                item: 'ATTRIBUTES_NAV_NAME',
                access: exports.PERMISSIONS.WORKSPACE.SHIFTATTRIBUTES,
            },
            {
                item: 'GROUPS_TITLE',
                access: exports.PERMISSIONS.WORKSPACE.GROUPS,
            },
        ],
    },
    {
        item: 'BACKOFFICE',
        access: 'BACKOFFICE',
        icon: _ui_1.Icon.ICONS.computer,
        nestedItems: [
            {
                item: 'ATTENDANCE',
                access: exports.PERMISSIONS.BACKOFFICE.ATTENDANCE,
            },
            {
                item: 'BONUSES',
                access: exports.PERMISSIONS.BACKOFFICE.BONUSES,
            },
            {
                item: 'TERMINAL',
                access: exports.PERMISSIONS.BACKOFFICE.TERMINALS,
            },
            {
                item: 'BACKOFFICE_UNAVAILABILITY_REQUESTS',
                access: exports.PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS,
            },
            {
                item: 'EMPLOYEES',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_EMPLOYEE,
                disabled: true,
            },
            {
                item: 'WORKSPACE',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_WORKSPACE,
                disabled: true,
            },
            {
                item: 'WARNINGS_TITLE',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_WARNINGS,
                disabled: true,
            },
            {
                item: 'REPORTS_OVERTIME',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_OVERTIME,
                disabled: true,
            },
            {
                item: 'COVID_REPORT_TITLE_SHORT',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_CONTACTS,
                disabled: true,
            },
            {
                item: 'ORG_SP_WORKSPACES_PERFORMANCE',
                access: exports.PERMISSIONS.BACKOFFICE.REPORT_REVENUE,
                disabled: true,
            },
        ],
    },
    {
        item: 'SETTINGS',
        access: 'SETTINGS',
        icon: _ui_1.Icon.ICONS.settings,
        nestedItems: [
            {
                item: 'WS_SETTINGS_CARD_BASIC_SETTINGS_NAV',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSBASIC,
            },
            {
                item: 'PERMISSIONS',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSPERMISSIONS,
            },
            {
                item: 'NOTIFICATION',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSNOTIFICATIONS,
            },
            {
                item: 'EMPLOYEES',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSEMPLOYEES,
            },
            {
                item: 'OFFERS',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSOFFERS,
            },
            {
                item: 'CAPACITY',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSCAPACITIES,
            },
            {
                item: 'WS_SETTINGS_ATTENDANCE_AND_TERMINALS',
                access: exports.PERMISSIONS.SETTINGS.SETTINGSATTENDANCETERMINALS,
            },
            {
                item: 'WS_SETTINGS_APIKEYS_HEADER',
                access: exports.PERMISSIONS.SETTINGS.APIKEYS,
            },
            {
                item: 'PLUGIN',
                access: exports.PERMISSIONS.SETTINGS.PLUGINS,
            },
            {
                item: 'WS_SETTINGS_AUDIT_LOG',
                access: exports.PERMISSIONS.SETTINGS.AUDITLOGS,
                disabled: true,
            },
        ],
    },
    {
        item: 'COMMUNICATION',
        access: exports.PERMISSIONS.COMMUNICATION,
        icon: _ui_1.Icon.ICONS.comment,
        nestedItems: [
            {
                item: 'NEWS_FEED',
                access: exports.PERMISSIONS.COMMUNICATION.NEWS_FEED,
            },
            {
                item: 'CHAT',
                access: exports.PERMISSIONS.COMMUNICATION.CHAT,
                disabled: true,
            },
        ],
    },
];
