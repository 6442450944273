import React from 'react'
import moment from 'moment'
import pluginsConfig from '@app/const/plugin-config'

import {
  calendarUtil,
  multiSelectUtil,
  notification,
  routeUtil,
  miscUtil,
  permissionsUtil
} from '@app/util'
import {
  Button,
  Select,
  Tooltip,
  Icon,
  Checkbox,
  Dropdown,
  Text,
  CircledNumber,
  Flex,
  Alert,
  Spacing,
  RadioGroup
} from '@ui'
import {
  SearchInput,
  WarningsTooltip
} from './components'
import { PERMISSIONS } from '@app/const'
import Filters from './filters.js'
import { t } from 'i18next'
import './index.scss'
import connect from './connect'

class Controls extends React.Component {
  searchTimer = null

  constructor (props) {
    super(props)
    this.state = {
      searchActive: false,
      searchQuery: '',
      filtersActive: false
    }
    this.applySearch = this._applySearch.bind(this)
    this.handleKeypress = this._handleKeypress.bind(this)
    this.setCalendarWarnings = this._setCalendarWarnings.bind(this)

    this.searchTimer = setTimeout(function () { }, 0)
  }

  _applySearch (query) {
    const { shifts, employees, positions, calendar, setCalendarSearch } = this.props

    if (query === '') {
      setCalendarSearch({ query: query, results: [] })
      return
    }
    query = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

    // un-highlight all the events
    const allHl = [...document.querySelectorAll('.ds-c-event.is-highlighted')]
    allHl.map(el => {
      el.classList.remove('is-highlighted')
    })

    if (query.length < 2) {
      return false
    } else {
      if (!calendar || !calendar.date) return

      // get relevant items (shifs, unavs and employees in calendar's period)
      let relevantItems = (shifts || []).map(s => {
        if (s.period && moment(s.period.start).isSameOrAfter(calendarUtil.getReloadPeriod(calendar).start) && moment(s.period.start).isSameOrBefore(calendarUtil.getReloadPeriod(calendar).end)) {
          const ee = employees[s.userId]
          const pos = positions.find(p => s.positionId === p.id)
          // shift events
          return {
            // elemIdentifier: calendarUtil.getEvtIdentifier(s.id, moment(s.period.start).format('YYYY-MM-DD'), true),
            userId: s.userId,
            str:
              (ee ? ee.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '') + '_' +
              (pos ? pos.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '') + '_' +
              moment(s.period.start).format('H:mm') + '-' + moment(s.period.end).format('H:mm')

          }
        }
      })

      Object.keys(employees).map(idx => employees[idx]).map(ee => {
        relevantItems.push({
          userId: ee.id,
          str: ee.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
        })
      })
      relevantItems = relevantItems.filter(el => typeof el !== typeof undefined)

      // match them to search query
      const matchedRows = []
      const queryWords = query.split(' ')
      relevantItems.map(ev => {
        const matches = []
        queryWords.map((qw) => {
          const m = ev.str.match(qw)
          if (m && m.length) matches.push(qw)
        })
        // all query words matched
        if (matches.length === queryWords.length) {
          if (!matchedRows.includes(ev.userId)) matchedRows.push(ev.userId)
        }
      }).filter(el => typeof el !== typeof undefined)

      // and send the search results to store
      setCalendarSearch({ query: query, results: matchedRows.map(m => { return { type: 'row', id: m } }) })

      // scroll to the topmost matched element
      setTimeout(() => {
        const whichSection = ['is-section-bottom', 'is-section-unassigned', 'is-section-offers']
        whichSection.forEach((which) => {
          let topmostPos = 9999999
          document.querySelectorAll('.ds-c-section.' + which + ' .ds-c-row.is-highlighted').forEach(el => {
            const offset = el.offsetTop - document.querySelector('.ds-c-section.' + which).offsetTop
            if (offset < topmostPos) topmostPos = offset
          })
          const el = document.querySelector('.ds-c-section.' + which)
          if (el) el.scrollTo({ top: topmostPos - 4, behavior: 'smooth' })
        })
      }, 700)
    }
  }

  // handle keyboard shortcuts on manager's calendar
  _handleKeypress (evt) {
    const { setCalendar, calendar, calendarMultiSelect, setCalendarMultiSelect, isPluginEnabled, shifts } = this.props
    const ctrlDown = evt.ctrlKey || evt.metaKey

    // Ctrl+F
    if (ctrlDown && evt.keyCode === 70) {
      if (!this.state.searchActive) {
        // open search
        this.setState((s) => Object.assign({}, s, { searchActive: true }))
        evt.preventDefault()
      } else {
        // or select the text in search input
        const el = document.querySelector('.ds-st-search .ds-input-el.tt-input')
        if (el) el.select()
        evt.preventDefault()
      }
    }

    // Ctrl+P
    if (ctrlDown && evt.keyCode === 80) {
      this.props.setModal('export-excel')
      evt.preventDefault()
    }

    // Esc
    if (evt.keyCode === 27) {
      // disable open column's context menu tooltip
      if (calendar.contextMenuForColumn) setCalendar({ contextMenuForColumn: undefined })

      // deactivate the search if it's active
      if (this.state.searchActive) this.setState((s) => Object.assign({}, s, { searchActive: false }))

      // cancel multiselection
      if (calendarMultiSelect.isActive) {
        multiSelectUtil.multiSelectClear()
      }
    }

    // Delete
    if (evt.keyCode === 46) {
      // delete
      if (multiSelectUtil.getMultiSelectAllowedActionTypes(isPluginEnabled).some(a => a.type === 'delete')) multiSelectUtil.multiSelectExecuteAction('delete')
    }

    // Ctrl+C
    if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 67) {
      if (multiSelectUtil.getMultiSelectAllowedActionTypes(isPluginEnabled).some(a => a.type === 'copy')) {
        notification.success({ code: 'eventCopied' })
        setCalendarMultiSelect({
          action: 'copy',
          isSelectingTargets: true,
          targets: []
        })
      } else if (multiSelectUtil.getMultiSelectAllowedActionTypes(isPluginEnabled).some(a => a.type === 'applyShiftTemplate')) {
        notification.success({ code: 'eventCopied' })
        setCalendarMultiSelect({
          action: 'applyShiftTemplate',
          isSelectingTargets: true,
          targets: []
        })
      }
    }

    // Ctrl+V
    if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 86 && !this.state.msExecuteInProgress) {
      if (calendarMultiSelect.sourceEvents.length === 1 && calendarMultiSelect.targets.length > 0) {
        // copying an event
        if (calendarMultiSelect.action === 'copy') {
          if (calendarMultiSelect.sourceEvents[0].positionId) {
            multiSelectUtil.multiSelectCopyShiftToTargets(calendarMultiSelect.sourceEvents[0]).then(() => { multiSelectUtil.multiSelectClear() })
          } else {
            multiSelectUtil.multiSelectCopyUnavailabilityToTargets(calendarMultiSelect.sourceEvents[0]).then(() => { multiSelectUtil.multiSelectClear() })
          }
        }
        // applying a shift template
        if (calendarMultiSelect.action === 'applyShiftTemplate') {
          multiSelectUtil.createShiftsFromShiftTemplateMulti(calendarMultiSelect.sourceEvents[0], calendarMultiSelect.targets)
        }
      }
    }

    // Ctrl+A
    if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 65) {
      const allEvents = shifts.filter(s => moment(s.period.start).isSameOrAfter(moment(calendar.date).startOf(calendar.view)) && moment(s.period.end).isBefore(moment(calendar.date).endOf(calendar.view))) || []
      setCalendarMultiSelect({
        action: null,
        isSelectingTargets: false,
        targets: [],
        sourceEvents: allEvents
      })
      evt.preventDefault()
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeypress, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeypress, false)
  }

  _setCalendarWarnings (whichWarnings) {
    const { calendarFilters, setCalendarFilters, setCalendar, calendar } = this.props
    let newFilters = calendarFilters.slice()

    newFilters = newFilters.filter(fil => !fil.hideWarnings)
    if (whichWarnings) newFilters.push({ hideWarnings: whichWarnings })

    setCalendarFilters(newFilters).then(() => {
      // force-reload all the data in the calendar
      setCalendar(calendar, true)
    })
  }

  render () {
    const {
      me,
      className,
      offers,
      calendarFilters,
      calendar,
      setCalendar,
      setModal,
      workspace,
      workspaceId,
      isPluginEnabled,
      warningCounts,
      countWarningsByType,
      calZoom,
      calZoomOnChange,
      deleteLiveShift,
      shifts,
      employees,
      isLoadingRelevantData,
      togglePlugin
    } = this.props

    const cN = ['ds-c-controls']
    if (className) cN.push(className)
    if (this.state.filtersActive) cN.push('display-filters')

    const toggleFiltersActive = () => { this.setState(s => Object.assign({}, s, { filtersActive: !this.state.filtersActive })) }
    if (this.state.filtersActive) {
      return (
        <div className={cN.join(' ')}>
          <Filters toggleFiltersActive={toggleFiltersActive} />
        </div>
      )
    }

    const monthOpts = []
    if (calendar.view === 'month') {
      for (var i = -6; i <= 6; i++) {
        const label = moment(calendar.date).add(i, 'months').format('MMMM YYYY')
        monthOpts.push({
          label: label.charAt(0).toUpperCase() + label.slice(1),
          value: moment(calendar.date).add(i, 'months').format('YYYY-MM-DD')
        })
      }
    }

    const zoomOptions = [
      { label: '50%', value: 50 },
      { label: '75%', value: 75 },
      { label: '90%', value: 90 },
      { label: '100%', value: 100 },
      { label: '110%', value: 110 },
      { label: '125%', value: 125 },
      { label: '150%', value: 150 }
    ]

    const hiddenWarnings = calendarFilters.find(fil => fil.hideWarnings)
    const laborlawPlugin = workspace?.plugins?.find(p => p.plugin === 'laborlaw')
    const laborlawPluginEnabled = Boolean(laborlawPlugin && laborlawPlugin.enabled)
    const offersPlugin = Object.assign({}, workspace?.plugins?.find(p => p.plugin === 'offers'), pluginsConfig.SUPPORTED_PLUGINS.find(p => p.id === 'offers'))

    const shiftWarningTypes = (laborlawPlugin && laborlawPlugin.options) ? laborlawPlugin.options.warnings.filter(w => w.type === 'shift') : []
    let warningCountsFiltered = 0
    if (!hiddenWarnings) {
      warningCountsFiltered = warningCounts
    } else {
      if (hiddenWarnings.hideWarnings !== 'all' && hiddenWarnings.hideWarnings !== true) {
        Object.keys(countWarningsByType).filter(k => k !== 'all' && k !== true).forEach(k => {
          if (!hiddenWarnings || !hiddenWarnings.hideWarnings || (!hiddenWarnings.hideWarnings.includes(k) && !hiddenWarnings.hideWarnings.find(hw => k.includes(hw + '_')) /* sometimes, we need type like 'shiftDuration' to also match warnings like 'shiftDuration_long'. they appear, but are not in plugins.options */)) {
            warningCountsFiltered += countWarningsByType[k]
          }
        })
      }
    }

    const calPeriod = calendar && calendarUtil.getReloadPeriod(calendar)
    const filteredShiftsInPeriod = ((shifts && shifts.length && calPeriod) ? (calendarUtil.getFilteredEvents(shifts, calendarFilters)) : []).filter(s => moment(s.period.start).isSameOrAfter(calPeriod.start) && moment(s.period.start).isSameOrBefore(calPeriod.end))

    return (
      <div className={cN.join(' ')}>
        <Flex justify={Flex.POSITION.SPC_BETWEEN} stretch>
          <Flex>
            {/* assistant */}
            {isPluginEnabled('assistant') && (
              <>
                <Button
                  style={Button.STYLES.CONTAINED}
                  bold
                  label={t('ASSISTANT')}
                  onClick={() => {
                    this.props.setSidebar('planning')
                  }}
                />
                <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
              </>
            )}
            {/* planning */}
            <Button
              style={isPluginEnabled('assistant') ? Button.STYLES.OUTLINED : Button.STYLES.CONTAINED}
              ico={Icon.ICONS.plus}
              bold
              label={t('PLANNING_BTN_LABEL')}
              onClick={() => {
                setModal('planning')
              }}
              disabled={miscUtil.isMyCalendarLocked(calendar?.period?.end, workspace)}
            />
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* add event */}
            <Dropdown
              label={null}
              singleSelect
              sortedOptions={false}
              size={Dropdown.SIZES.MEDIUM}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.OUTLINED}
              disabled={miscUtil.isMyCalendarLocked(calendar?.period?.end, workspace)}
              options={[
                { value: 'addShift', label: t('ADD_SHIFT') },
                { value: 'addAbsence', label: t('ADD_ABSENCE') },
                { value: 'addUnavailability', label: t('ADD_UNAVAILABILITY') },
                { value: 'addAvailability', label: t('ADD_UNAVAILABILITY_AVAIL') },
                { value: '', label: '' },
                { value: 'addPosition', label: t('ADD_POSITION') },
                { value: 'addTemplate', label: t('ADD_TEMPLATE_CONTROLS') },
                (isPluginEnabled('localities') ? { value: 'addLocality', label: t('ADD_LOCALITY_CONTROLS') } : null),
                { value: 'addCycle', label: t('ADD_CYCLE_CONTROLS') }
              ].filter(Boolean)}
              value={[]}
              placeholder={t('ADD')}
              testid='add-button'
              onChange={(v) => {
                switch (v.value) {
                  case 'addShift':
                    setModal('extra-shift', {
                      type: 'live',
                      openedFromCalControls: true,
                      day: moment(calendar.date).format('YYYY-MM-DD')
                    })
                    break
                  case 'addUnavailability':
                    setModal('extra-unavailability', {
                      userId: null,
                      newAvailabilityOrTimeOff: 'unavailability',
                      day: moment(calendar.date).format('YYYY-MM-DD')
                    })
                    break
                  case 'addAvailability':
                    setModal('extra-unavailability', {
                      userId: null,
                      newAvailabilityOrTimeOff: 'availability',
                      day: moment(calendar.date).format('YYYY-MM-DD')
                    })
                    break
                  case 'addAbsence':
                    setModal('extra-unavailability', {
                      newAvailabilityOrTimeOff: 'timeOff',
                      userId: null,
                      day: moment(calendar.date).format('YYYY-MM-DD')
                    })
                    break
                  case 'addPosition':
                    setModal('position-editor')
                    break
                  case 'addTemplate':
                    routeUtil.navigate('/workspace/day-templates/create')
                    break
                  case 'addLocality':
                    setModal('extra-locality', { employees })
                    break
                  case 'addCycle':
                    routeUtil.navigate('/workspace/cycles/create')
                    break
                  default:
                    return false
                }
              }}
            />
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* offers */}
            <Button
              onClick={() => {
                if (isPluginEnabled('offers')) {
                  setModal('offers', {
                    selectedShiftIds: [],
                    action: offers.find(off => off.status === 'managerRequestApproval')
                      ? 'approve'
                      : offers.find(off => off.status === 'managerResultApproval')
                        ? 'resolve'
                        : offers.find(off => off.status === 'employeeReplies')
                          ? 'show'
                          : 'create'
                  })
                } else {
                  setModal('plugin-detail', { plugin: offersPlugin })
                }
              }}
              ico={Icon.ICONS.person}
              bold
              disabled={miscUtil.isMyCalendarLocked(calendar?.period?.end, workspace)}
            >
              <div className='ds-st-actions-label'>{t('OFFERS')}</div>
              {offers.filter(off => ['managerRequestApproval', 'managerResultApproval'].includes(off.status)).length > 0 && (
                <div className='ds-c-set-offers-count'>
                  {offers.filter(off => ['managerRequestApproval', 'managerResultApproval'].includes(off.status)).length}
                </div>
              )}
            </Button>
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* undo */}
            <Button
              ico={Icon.ICONS.back}
              bold
              onClick={() => {
                setModal('publish-changes', { undo: true })
              }}
              label={t('REVERT_CHANGES')}
              disabled={miscUtil.isMyCalendarLocked(calendar?.period?.end, workspace)}
            />
          </Flex>
          <Flex justify={Flex.POSITION.END} stretch>
            {/* loading notice */}
            <div
              className='ds-c-loading-indicator'
              style={{ opacity: isLoadingRelevantData?.length ? 1 : 0 }}
            >
              <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
              <Alert
                text={t('LOADING').charAt(0).toUpperCase() + t('LOADING').slice(1) + ': ' + isLoadingRelevantData.map(l => t(l).toString().toLowerCase().trim()).join(', ')}
                type={Alert.TYPES.WARNING}
                customIco={Icon.ICONS.loader}
                size={Alert.SIZES.FULL_WIDTH}
                variant={Alert.VARIANTS.NORMAL}
              />
              <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            </div>

            {!this.state.searchActive
              ? (
                <Button
                  ico={Icon.ICONS.search}
                  onClick={() => {
                    this.setState((s) => Object.assign({}, s, { searchActive: true }))
                  }}
                  bold
                >
                  {/* search button */}
                  <div className='ds-st-actions-label'>{t('SEARCH')}</div>
                </Button>
              )
              : (
                <SearchInput
                  onChange={(val) => {
                    this.setState((s) => Object.assign({}, s, { searchQuery: val }))
                    clearTimeout(this.searchTimer)
                    this.searchTimer = setTimeout(this.applySearch, 800, val)
                  }}
                  onKeyDown={({ keyCode }) => {
                    if (keyCode === 27) { // escape
                      this.setState((s) => Object.assign({}, s, { searchActive: false, searchQuery: '' }))
                      this.applySearch('')
                    }
                  }}
                  onCancel={() => {
                    this.setState((s) => Object.assign({}, s, { searchActive: false, searchQuery: '' }))
                    this.applySearch('')
                  }}
                  value={this.state.searchQuery}
                />
              )}
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* Set up Filters */}
            <Button
              bold
              onClick={toggleFiltersActive}
              ico={Icon.ICONS.filters}
            >
              {t('FILTER')}
              {calendarFilters.filter(f => !f.hideWarnings).length
                ? (
                  <>
                    <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
                    <CircledNumber number={calendarFilters.filter(f => !f.hideWarnings).length} color={CircledNumber.COLORS.WARNING} />
                  </>
                ) : null}
            </Button>
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* warnings */}
            <WarningsTooltip
              workspaceId={workspaceId}
              calendarDate={calendar?.date}
              laborlawPluginEnabled={laborlawPluginEnabled}
              laborlawPlugin={laborlawPlugin}
              warningCountsFiltered={warningCountsFiltered}
              hiddenWarnings={hiddenWarnings}
              onChangeEnabledAll={(val) => {
                if (hiddenWarnings && (hiddenWarnings.hideWarnings === 'all' || hiddenWarnings.hideWarnings === true)) {
                  this.setCalendarWarnings(undefined)
                } else {
                  this.setCalendarWarnings('all')
                }
              }}
              onHandlePluginChange={() => { togglePlugin(laborlawPlugin, calendar) }}
              shiftWarningTypes={shiftWarningTypes}
              countWarningsByType={countWarningsByType}
              setCalendarWarnings={this.setCalendarWarnings}
            />
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* export */}
            <Button
              bold
              onClick={() => {
                if (me.hasPassword) {
                  setModal('export-excel')
                }
              }}
              ico={Icon.ICONS.export}
              label={t('TABLE_EXPORT')}
            />
            <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
            {/* View */}
            <Tooltip
              clickable
              className='ds-c-view-tooltip'
              position={Tooltip.POSITIONS.LEFT}
              width='20.8rem'
              anchor={
                <Button
                  bold
                  ico={Icon.ICONS.view}
                  label={t('VIEW')}
                />
              }
            >
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>{t('CALENDAR_SETTINGS')}</Text>
              </Spacing>
              <div className='separator' />
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                <div className='ds-c-view-zoom-section' onClick={(e) => { e.stopPropagation() }}>
                  {/* calendar rows */}
                  <Spacing size={Spacing.SIZES.SIZE_10} />
                  <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
                    {t('CALENDAR_ROWS')}
                  </Text>
                  <Spacing size={Spacing.SIZES.SIZE_10} />
                  <ul className='ds-c-view-rows-list' style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {permissionsUtil.canWrite(PERMISSIONS.CALENDAR)
                      ? (
                        <>
                          <li
                            onClick={(e) => {
                              setCalendar({ displayRowTemplates: !([true, 'true'].includes(calendar.displayRowTemplates)) })
                              e.stopPropagation()
                            }}
                          >
                            <Checkbox checked={calendar.displayRowTemplates /* default: false */}>
                              <Text type={Text.TYPES.BODY_LARGE}>
                                {t('CALENDAR_ROWS_TEMPLATES')}
                              </Text>
                            </Checkbox>
                          </li>
                          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.VERTICAL} />
                        </>)
                      : null}
                    <li
                      onClick={(e) => {
                        setCalendar({ displayRowUnassigned: !([true, 'true'].includes(calendar.displayRowUnassigned)) })
                        e.stopPropagation()
                      }}
                    >
                      <Checkbox
                        checked={![false, 'false'].includes(calendar.displayRowUnassigned) /* default: true */}
                      >
                        <Text type={Text.TYPES.BODY_LARGE}>
                          {t('CALENDAR_ROWS_UNASSIGNED')}
                        </Text>
                      </Checkbox>
                    </li>
                    {isPluginEnabled('offers') ? (
                      <>
                        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.VERTICAL} />
                        <li
                          onClick={(e) => {
                            setCalendar({ displayRowOffers: !([true, 'true'].includes(calendar.displayRowOffers)) })
                            e.stopPropagation()
                          }}
                        >
                          <Checkbox
                            checked={![false, 'false'].includes(calendar.displayRowOffers) /* default: true */}
                          >
                            <Text type={Text.TYPES.BODY_LARGE}>
                              {t('CALENDAR_ROWS_OFFERS')}
                            </Text>
                          </Checkbox>
                        </li>
                      </>
                    ) : null}
                  </ul>
                  <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL} />

                  <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
                    {t('STATS_CAPACITIES' + (isPluginEnabled('capacityplanning') ? '' : '_NOCAP'))}
                  </Text>
                  <Spacing size={Spacing.SIZES.SIZE_10} type={Spacing.TYPES.VERTICAL} />

                  {/* display/hide statistics & capacity planning */}
                  <RadioGroup
                    direction={RadioGroup.DIRECTIONS.VERTICAL}
                    value={Boolean(calendar.displayStatistics).toString() + '_' + Boolean(calendar.displayCapacities).toString()}
                    onChange={(v) => {
                      const vals = v.split('_').map(val => (val === 'true'))
                      setCalendar({ displayStatistics: vals[0], displayCapacities: vals[1] })
                    }}
                    options={[
                      { value: Boolean(false).toString() + '_' + Boolean(false).toString(), label: t('STATS_CAPACITIES_OFF') },
                      { value: Boolean(true).toString() + '_' + Boolean(false).toString(), label: t('STATISTICS') },
                      (isPluginEnabled('capacityplanning') ? { value: Boolean(false).toString() + '_' + Boolean(true).toString(), label: t('CAPACITY') } : null)
                    ].filter(Boolean)}
                  />

                  <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL} />

                  {/* zoom */}
                  <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.CENTER}>
                    <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
                      {t('VIEW_ZOOM')}
                    </Text>
                    <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
                      <Button
                        style={Button.STYLES.LIGHT}
                        onClick={(e) => {
                          e.stopPropagation()
                          const idx = zoomOptions.findIndex(zo => zo.value === parseInt(calZoom))
                          if (idx >= 1) calZoomOnChange(zoomOptions[idx - 1].value)
                        }}
                        ico={Icon.ICONS.minus}
                      />
                      <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                      <Select
                        options={zoomOptions}
                        value={parseInt(calZoom)}
                        onChange={(val) => {
                          if (calZoomOnChange) calZoomOnChange(val)
                        }}
                      />
                      <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                      <Button
                        style={Button.STYLES.LIGHT}
                        onClick={(e) => {
                          e.stopPropagation()
                          const idx = zoomOptions.findIndex(zo => zo.value === parseInt(calZoom))
                          if (idx < (zoomOptions.length - 1)) calZoomOnChange(zoomOptions[idx + 1].value)
                        }}
                        ico={Icon.ICONS.plus}
                      />
                    </Flex>
                  </Flex>
                </div>
              </Spacing>

              <div className='separator' />

              {/* delete all shifts */}
              {filteredShiftsInPeriod && filteredShiftsInPeriod.length && !miscUtil.isMyCalendarLocked(calendar?.period?.end, workspace) ? (
                <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_16}>
                  <span
                    className='ds-link'
                    style={{ color: '#f44c4c' }}
                    onClick={() => {
                      setModal('confirm', {
                        title: t('MULTI_ACTION_DEL_CONFIRM_TITLE'),
                        type: Alert.TYPES.ERROR,
                        confirmLabel: t('DELETE'),
                        onConfirm: async () => {
                          deleteLiveShift(null, filteredShiftsInPeriod.map(s => s.id)) // del shifts
                        },
                        cancelLabel: t('CANCEL'),
                        onCancel: () => { },
                        subtitle: t('MULTI_ACTION_DEL_CONFIRM_DELETE_SUBTITLE_X', { x: filteredShiftsInPeriod.length }),
                        recordsList: filteredShiftsInPeriod,
                        buttonColor: 'red',
                        overSidebar: true
                      })
                    }}
                  >
                    <Flex direction={Flex.DIRECTION.ROW}>
                      <Icon ico={Icon.ICONS.delete} color={Icon.COLORS.RED} />
                      <Text color={Text.COLORS.DANGER}>
                        {t('CAL_DELETE_ALL_SHIFTS')}
                      </Text>
                    </Flex>
                  </span>
                </Spacing>
              ) : null}

            </Tooltip>
          </Flex>
        </Flex>
      </div>
    )
  }
}

export default connect(Controls)
