import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Icon } from '@ui'
import classNames from 'classnames'
import { t } from 'i18next'
import { useFloatable } from '@app/util'

import './style.scss'

const STYLES = Object.freeze({
  DEFAULT: 'default',
  BORDERLESS: 'borderless'
})

const RETURN_TYPES = Object.freeze({
  STRING: 'string',
  MOMENT: 'moment'
})

export const MonthSelect = ({
  onChange,
  value,
  style,
  returnType,
  disabled,
  label,
  forbiddenPast,
  forbiddenFuture
}) => {
  const floatable = useFloatable()
  const [year, setYear] = useState(value ? moment(value) : moment())
  const handleChange = (month, index) => {
    const stringValue = moment(year).format('YYYY-') + String(index).padStart(2, '0')
    returnType === RETURN_TYPES.MOMENT ? onChange(moment(stringValue)) : onChange(stringValue)
    floatable.setOpen(false)
  }
  return (
    <div
      className={classNames(
        'month-select',
        style,
        { disabled: disabled }
      )}
      data-testid='month-select'
    >
      {label && <div className='input-label'>{label}</div>}
      <div className='toggler' {...floatable.floatAnchor}>
        {t('MONTH_' + moment(value).format('M'))}
        {moment(value).format(' YYYY')}
        {style === STYLES.BORDERLESS && <Icon ico={Icon.ICONS.arrowDown} size={Icon.SIZES.SMALL} />}
      </div>
      {floatable.open && (
        <div className='month-dropdown' {...floatable.floatContainer}>
          <div className='header'>
            <Icon
              onClick={() => setYear(moment(year).subtract(1, 'year'))}
              ico={Icon.ICONS.arrowLeft}
            />
            {moment(year).format('YYYY')}
            <Icon
              onClick={() => setYear(moment(year).add(1, 'year'))}
              ico={Icon.ICONS.arrowRight}
            />
          </div>
          <div className='separator' />
          <div className='month-list'>
            {moment.months().map((month, index) => {
              const isActive = moment(value).isSame(moment(year).format('YYYY-') + (index + 1), 'month')
              const stringValue = moment(year).format('YYYY-') + String(index + 1).padStart(2, '0')
              const isPast = stringValue < moment().format('YYYY-MM')
              const isFuture = stringValue > moment().format('YYYY-MM')
              const isDisabled = (forbiddenPast && isPast) || (forbiddenFuture && isFuture)
              return (
                <div
                  key={month}
                  className={classNames(
                    'month-item',
                    { active: isActive, disabled: isDisabled }
                  )}
                  onClick={isDisabled ? null : () => handleChange(month, index + 1)}
                >
                  {t('MONTH_' + (index + 1).toString())}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

MonthSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment)
  ]),
  style: PropTypes.oneOf(Object.values(STYLES)),
  returnType: PropTypes.oneOf(Object.values(RETURN_TYPES)),
  disabled: PropTypes.bool,
  forbiddenPast: PropTypes.bool,
  forbiddenFuture: PropTypes.bool,
  label: PropTypes.string
}
MonthSelect.defaultProps = {
  value: moment(),
  style: STYLES.DEFAULT,
  returnType: RETURN_TYPES.MOMENT,
  disabled: false,
  label: null,
  forbiddenPast: false,
  forbiddenFuture: false
}

MonthSelect.STYLES = STYLES
MonthSelect.RETURN_TYPES = RETURN_TYPES
