import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import loadRoleStatsMulti from '@app/ac/load-role-stats-multi'
import {
  requestTimeOffUpdateMulti,
  requestTimeOffCreateMulti,
  requestAvailabilityUpdateMulti,
  requestAvailabilityCreateMulti
} from '../request'

import { calendarUtil } from '@app/util'
// import { setAvailabilities, setTimeOffs } from '@app/action'

export default ({ evts, unavId }) => {
  return async (dispatch) => {
    const { auth, workspaceId, calendar } = store.getState()
    if (!workspaceId || !evts?.length || evts.some(e => !e.period?.start || !e.period?.end || !e.user)) return

    const isCreatingNew = Boolean(unavId) === false
    const availOrTimeOff = calendarUtil.isEventAvailabilityOrTimeOff(evts[0])
    if (evts.some(e => calendarUtil.isEventAvailabilityOrTimeOff(e) !== availOrTimeOff)) return

    // prepare the input data
    const data = []
    evts.forEach(evt => {
      const {
        period,
        periodType,
        user,
        available,
        categoryId,
        contractId,
        note,
        recurrence,
        workMinutesBy,
        workMinutes,
        workMinutesInclusion,
        attendanceApproved,
        attachments,
        recurrenceUpdateFollowing
      } = evt
      data.push(availOrTimeOff === 'timeOff'
        ? { // input data for TimeOff
          id: unavId || undefined,
          user,
          period,
          periodType,
          categoryId,
          contractId,
          workMinutesBy,
          workMinutes,
          note,
          recurrence,
          attendanceApproved,
          attachments,
          workMinutesInclusion: isCreatingNew ? workMinutesInclusion : undefined,
          recurrenceUpdateFollowing: isCreatingNew ? undefined : recurrenceUpdateFollowing
        }
        : { // input data for availability / unavailability
          id: unavId || undefined,
          user,
          period,
          available: Boolean(available),
          note: note,
          recurrence,
          recurrenceUpdateFollowing: isCreatingNew ? undefined : recurrenceUpdateFollowing
        })

      if (availOrTimeOff !== 'timeOff') {
        delete data[data.length - 1].categoryId
      }
      if (data[data.length - 1].recurrence) {
        const newRecurrence = { ...data[data.length - 1].recurrence }
        delete newRecurrence.id
        data[data.length - 1].recurrence = recurrenceUpdateFollowing ? newRecurrence : undefined
      }
    })

    dispatch(isLoading('set-availability-or-time-off'))

    let result = null
    if (!isCreatingNew) {
      // if 'unavId' is set, we're modifying the existing event
      const requestVars = {
        workspace: workspaceId,
        data: data.map(d => Object.assign({ id: unavId }, d))
      }
      if (availOrTimeOff === 'timeOff') {
        result = await requestTimeOffUpdateMulti(requestVars, auth)
        /*
        NOTE: No need to update the store here. This is done by WebSockets
        const updatedTimeoffs = [...timeOffs]
        const timeoffIndex = updatedTimeoffs.findIndex((item) => item.id === unavId)
        updatedTimeoffs[timeoffIndex] = {
          ...updatedTimeoffs[timeoffIndex],
          ...data
        }
        dispatch(setTimeOffs(updatedTimeoffs))
        */
      } else {
        result = await requestAvailabilityUpdateMulti(requestVars, auth)
        /*
        NOTE: No need to update the store here. This is done by WebSockets
        const updatedAvailabilities = [...availabilities]
        const availIndex = updatedAvailabilities.findIndex((item) => item.id === unavId)
        updatedAvailabilities[availIndex] = {
          ...updatedAvailabilities[availIndex],
          ...data
        }
        dispatch(setAvailabilities(updatedAvailabilities))
        */
      }
    } else {
      // otherwise, we're just creating a new event
      const requestVars = {
        workspace: workspaceId,
        data
      }
      result = availOrTimeOff === 'timeOff'
        ? await requestTimeOffCreateMulti(requestVars, auth)
        : await requestAvailabilityCreateMulti(requestVars, auth)
    }

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    // if we added/created a timeOff, and we have the store.calendar loaded,
    // reload the role stats (because the new timeOff might change the values there)
    if (result && !result.error && availOrTimeOff === 'timeOff' && calendar && calendar.date) {
      dispatch(loadRoleStatsMulti(calendar.date))
    }

    dispatch(isLoading('set-availability-or-time-off', true))
    return result
  }
}
